<template>
  <div>
    <Menu />
    <div class="contact">
      <div class="contact__opacity"></div>
      <div class="contact__content">
        <h1 class="contact__h1">CONTÁCTENOS</h1>
        <div class="formGroup">
          <form class="contact__form" @submit.prevent="onSubmit">
            <p class="contact__title">Formulario de contácto</p>
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="form-group" :class="{ 'form-group--error': $v.form.name.$error }">
                  <input
                    class="form__input contact__input"
                    type="text"
                    placeholder="Nombre"
                    filled
                    v-model="$v.form.name.$model"
                  />
                </div>
                <div v-if="$v.form.name.$model === ''">
                  <div class="error" v-if="!$v.form.name.required">Dato requerido.</div>  
                </div>
                <div class="error" v-if="!$v.form.name.maxLength">Maximo {{$v.form.name.$params.maxLength.max}} carácteres.</div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group" :class="{ 'form-group--error': $v.form.phone.$error }">
                  <input
                    class="contact__input"
                    type="number"
                    placeholder="Teléfono"
                    filled
                    v-model="$v.form.phone.$model"
                  />
                  
                </div>
                <div v-if="$v.form.phone.$model === ''">
                  <div class="error" v-if="!$v.form.phone.required">Dato requerido.</div>
                </div>
                
                <div class="error" v-if="!$v.form.phone.minLength">Minimo {{$v.form.phone.$params.minLength.min}} numeros.</div>
                <div class="error" v-if="!$v.form.phone.maxLength">Maximo {{$v.form.phone.$params.maxLength.max}} numeros.</div>
              </div>
            </div>
            <div class="form-group" :class="{ 'form-group--error': $v.form.email.$error }">
              <input
                class="contact__input"
                type="email"
                placeholder="Correo electrónico"
                filled
                v-model="$v.form.email.$model"
              />
            </div>
            <div v-if="$v.form.email.$model === ''">
              <div class="error" v-if="!$v.form.email.required">Dato requerido.</div>
            </div>
            <div class="error" v-if="!$v.form.email.email">Ingrese email valido.</div>
            <div class="error" v-if="!$v.form.email.maxLength">Maximo {{$v.form.email.$params.maxLength.max}} carácteres.</div>
            <div class="form-group" :class="{ 'form-group--error': $v.form.msm.$error }">   
              <textarea
                class="contact__textarea"
                placeholder="Mensaje"
                filled
               
                v-model="$v.form.msm.$model"
              ></textarea>
            </div>
            <div v-if="$v.form.msm.$model === ''">
              <div class="error" v-if="!$v.form.msm.required">Dato requerido.</div>
            </div>
            <div class="error" v-if="!$v.form.msm.maxLength">Maximo {{$v.form.msm.$params.maxLength.max}} carácteres.</div>
            
            <div class="form-group">
              <button 
              class="btn contact__btn" 
              @click="onSubmit"
              :disabled="submitStatus === 'PENDING'"        
              >Enviar!
              </button>
            </div>
            <div v-if="!sent" class="typo__p form__success">
                <span class="col alert alert-danger" v-if="submitStatus === 'ERROR'"><i class="fal fa-times"></i> Por favor llené correctamente el formulario. </span>
            </div>
            <div v-if="!sent" class="typo__p form__success">
                <span class="col alert alert-success" v-if="submitStatus === 'PENDING'"><i class="fal fa-spinner"></i> Enviando... </span>
            </div>
            <!-- <transition name="fade"> -->
              <div v-if="sent" class="typo__p form__success">
                  <span class="col alert alert-success " v-if="submitStatus === 'OK'"><i class="fal fa-check-circle"></i> Mensaje enviado con éxito! </span>
              </div>
            <!-- </transition> -->

            <!-- <pre>{{$v}}</pre>  -->
          </form>
        </div>
      </div>
    </div>
    <Footer dinamic="true" />
  </div>
</template>

<script>
// import VueRecaptcha from 'vue-recaptcha';
import Vue from 'vue'
import Menu from "@/components/Menu";
import Footer from "@/components/Footer";
import { required, email, minLength, maxLength, between, submitStatus } from 'vuelidate/lib/validators'

export default {
  name: "Contacto",
  components: {
    Menu,
    Footer,
    // VueRecaptcha,
  },
  data() {
    return {
      form: {
        name: null,
        phone: null,
        email: null,
        msm: null,
      },
      submitStatus: null,
      sent: false,
      // sitekey: '6LeOwrkUAAAAAPGQ4PgjdGl_1lgaaJPUt_9AeXhj',
    };
  },
  validations: {
    form:{
      name: {required, maxLength: maxLength(60)},
      phone: {required, minLength: minLength(10), maxLength: maxLength(13)},
      email: {required, email, maxLength: maxLength(50)},
      msm: {required, maxLength: maxLength(500)},
    }
  },
  methods: {
    // onSubmit: function () {
    //   this.$refs.invisibleRecaptcha.execute()
    // },
    // onVerify: function (response) {
    //   console.log('Verify: ' + response)
    // },
    // onExpired: function () {
    //   console.log('Expired')
    // },
    // resetRecaptcha () {
    //   this.$refs.recaptcha.reset() // Direct call reset method
    // },
    onSubmit() {
      // console.log('submit!')
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
        // console.log("EROOR")
      } else {
        // do your submit logic here
        this.submitStatus = 'PENDING'
        // console.log("PENDIENTE")
        setTimeout(() => {
          try {
          var url = "https://www.qinspecting.com/Qinspecting/vistas/email.php";
          var formData = new FormData();
          formData.append("name", this.form.name);
          formData.append("phone", this.form.phone);
          formData.append("email", this.form.email);
          formData.append("msm", this.form.msm);
          // console.log(formData)
          fetch(url, {
            method: "POST",
            body: formData,
            mode: "no-cors",
            // dataType: 'multipart/form-data'
          }).then((res) => {
            this.sent = true;
            
            this.form.name = null;
            this.form.phone = null;
            this.form.email = null;
            this.form.msm = null;
            setTimeout(() => {
              this.sent = false;
              // console.log(this.sent)
            }, 2000);
          });
        } catch (e) {
          console.log(e);
        } 
          this.submitStatus = 'OK'
        }, 500)
        
      }
    },
  },
  metaInfo() {
    return {
      title: "Qinspecting |",
      titleTemplate: "%s Contáctenos y conozca nuestras características!",
      meta: [
        {
          name: "description",
          content:
            "Qinspecting, conozca las características que ofrecemos y gestione sus operaciones desde nuestra plataforma, Qinspecting aumenta la productividad de su flota. Contáctenos!",
        },
        {
          name: "keywords",
          content:
            "Qinspecting, checklist, preoperacionales, alistamientos, Transportes, Inspecciones, ChecklistElectronicos, Truck, mantenimiento, operaciones, hseq , talentohumano, gerencia, protocolodebioseguridad, protocolos, bioseguridad, Appears, Qinspecting",
        },
        {
          name: "author",
          content: "Appears",
        },
        {
          name: "robots",
          content: "Index, Follow",
        },
        // Tags twitter
        {
          name: "twitter:card",
          content: "summary",
        },
        {
          name: "twitter:site",
          content: "@appears",
        },
        {
          name: "twitter:creator",
          content: "@appears",
        },
        {
          name: "twitter:title",
          content:
            "Qinspecting | Contáctenos y conozca nuestras características!",
        },
        {
          name: "twitter:description",
          content:
            "Qinspecting, conozca las características que ofrecemos y gestione sus operaciones desde nuestra plataforma, Qinspecting aumenta la productividad de su flota. Contáctenos!",
        },
        {
          name: "twitter:image",
          content: "https://qinspecting.com/img/qinspecting.svg",
        },
        // Tags facebook
        {
          property: "og:locale",
          content: "es_CO",
        },
        {
          property: "og:site_name",
          content: "Qinspecting",
        },
        {
          property: "og:url",
          content: "https://www.qinspecting.com",
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          property: "og:title",
          content:
            "Qinspecting | Contáctenos y conozca nuestras características!",
        },
        {
          property: "og:description",
          content:
            "Qinspecting, conozca las características que ofrecemos y gestione sus operaciones desde nuestra plataforma, Qinspecting aumenta la productividad de su flota. Contáctenos!",
        },
        {
          property: "og:image",
          content: "https://qinspecting.com/img/qinspecting.svg",
        },
        {
          property: "og:image:secure_url",
          content: "https://qinspecting.com/img/qinspecting.svg",
        },
        {
          property: "og:image:width",
          content: "600",
        },
        {
          property: "og:image:height",
          content: "323",
        },
      ],
    }
  },
};
</script>

<style scoped>

.error {
  color: red;
  font-size: 13px;
}

.error:focus {
  outline-color: #F99;
} 

.contact {
  height: 800px;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-items: center;
  background-image: url("../assets/img/contact.jpg");
  background-image: no-repeat;
  background-image: fixed;
  background-image: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover; 
}

@media screen and (max-width: 767px) {
  .contact {
    height: 950px;
  }
}

.form__success{
  display: grid;
  justify-items: center;
}

.alert-success{  
  font-size: 17px;
  font-weight: 300;
  margin: 10px 0 0 0;
  text-align: center;
}

.alert-danger{  
  font-size: 17px;
  font-weight: 300;
  margin: 10px 0 0 0;
  text-align: center;
}

.contact__opacity {
  position: absolute;
  width: 100%;
  height: inherit;
  background: var(--black);
  opacity: 0.6;
}

.contact__h1 {
  color: var(--white);
  text-align: center;
}

.contact__content {
  z-index: 500;
}

.form-group {
  margin-bottom: 0;
}

.contact__form {
  width: 750px;
  background: var(--white);
  margin: 50px auto;
  box-shadow: 0 0 20px rgba(72, 98, 85, 0.6);
  border-radius: 10px;
  box-sizing: border-box;
  padding: 40px;
}

@media screen and (max-width: 767px) {
  .contact__form {
    width: auto;
    padding: 30px 25px;
    margin: 50px 30px;
  }
}

.contact__title {
  letter-spacing: 1px;
  font-size: 20px;
}

.contact__input,
.contact__textarea {
  width: 100%;
  height: 65px;
  padding: 10px 20px;
  box-sizing: border-box;
  margin-top: 15px;
  background: #e4e4e4;
  border: 0.1px solid #e4e4e4;
  outline: none;
  transform: all 0.5s ease;
}

.contact__textarea {
  height: 100px;
  margin-bottom: -7px;
}

.contact__btn {
  width: 100%;
  background: var(--primary);
  height: 50px;
  border-radius: 0;
  margin: 10px auto;
  color: var(--white);
  cursor: pointer;
}

.contact__btn:hover {
  background: var(--white);
  color: var(--primary);
  font-weight: bold;
  border: 2.5px solid var(--primary);
}

</style>
